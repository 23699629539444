import { useState, useEffect, useRef } from "react";
import { Link, useLocation} from "react-router-dom";
import React from "react";
import ScrollToTop from "../utils/ScrollToTop";
import PlytyWarstwowe from "./PlytyWarstwowe";
import { Helmet } from "react-helmet-async";

const PlytyWarstwoweComponent = () => {
  const [scrollY, setScrollY] = useState(0);
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const location = useLocation();

  const sectionRefs = {
    plytyPir: useRef(null),
    plytyWarstwowe: useRef(null),
  };
  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const hash = location.hash.replace('#', '');
    if (sectionRefs[hash]) {
      sectionRefs[hash].current.scrollIntoView({ behavior: 'smooth' });
      setMobileNavOpen(false);
    }
  }, [location]);

  const toggleMobileNav = () => {
    setMobileNavOpen(!isMobileNavOpen);
  };

  const closeMobileNav = () => {
    setMobileNavOpen(false);
  };

  return (
    <div>
      {/* Helmet section */}
      <Helmet>
                <title>Płyty Warstwowe Nowy Sącz Nal-Panel - dystrybutor Małopolska</title>
                <meta name="description" content="Solidne płyty warstwowe w Nowym Sączu – idealne do izolacji budynków. Sprawdź ofertę Nal-Panel i zyskaj trwałe, nowoczesne rozwiązania termoizolacyjne." />
      </Helmet>
      {/*End of Helmet Section*/}
      <header className={scrollY > 0 ? "scroll" : ""}>
        <div className="header-content">
          <div className="logo">
            <a href="/">
              <img src="/nal_panel.jpg" alt="Logo" />
            </a>
          </div>
          <div
            className={`mobile-nav-icon ${isMobileNavOpen ? "open" : ""}`}
            onClick={toggleMobileNav}
          >
            <div className="bar"></div>
            <div className="bar"></div>
            <div className="bar"></div>
          </div>
          <nav className="desktop-view">
            <ul>
              <li>
              <Link to="/">O nas</Link>
              </li>
              <li>
                <Link to="/plyty-pir">Płyty PIR</Link>
              </li>
              <li>
                <Link to="#">Płyty warstwowe</Link>
              </li>
              <li>
                <Link to ="/kontakt">Kontakt</Link>
              </li>
            </ul>
          </nav>
        </div>
        <div className={`mobile-view ${isMobileNavOpen ? "open" : ""}`}>
          <ul>
            <li>
            <Link to="/">O nas</Link>
            </li>
            <li>
            <Link to="/plyty-pir" onAnimationEnd={closeMobileNav}>Płyty PIR</Link>
            </li>
            <li>
              <Link to="#" onClick={closeMobileNav}>
              Płyty Warstwowe
              </Link>
            </li>
          
              <li>
                      <Link to="/kontakt">Kontakt</Link>
                </li>
          </ul>
        </div>
      </header>
      <main>
      <section id="produkty">
          <div>
              <PlytyWarstwowe/>
          </div>
        </section>         
      </main>
      <footer id="footer">
        <div className="footer-content">
          <div className="logo">
            <img src="/nal_panel_removedbg.png" alt="Footer Logo" />
          </div>
          <p>
            &copy; {new Date().getFullYear()} Nal-Panel. Wszelkie prawa
            zastrzeżone.
          </p>
        </div>
      </footer>
      <ScrollToTop/>
    </div>
  );
};

export default PlytyWarstwoweComponent;
