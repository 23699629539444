import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import ScrollToTop from "./utils/ScrollToTop"
import FAQ from "./FAQ";
import Opinie from "./Opinie";
import { Helmet } from "react-helmet-async";

const Home = () => {
  const [scrollY, setScrollY] = useState(0);
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const toggleMobileNav = () => {
    setMobileNavOpen(!isMobileNavOpen);
  };

  const closeMobileNav = () => {
    setMobileNavOpen(false);
  };

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);

    if (section) {
      const headerHeight = document.querySelector("header").offsetHeight;
      let targetPosition;
      if (window.innerWidth <= 768) {
        targetPosition = section.offsetTop - 100;
      } else {
        targetPosition = section.offsetTop - headerHeight - 15;
      }

      window.scrollTo({
        top: targetPosition,
        behavior: "smooth",
      });
    }
  };
  useEffect(() => {
    if (location.state?.scrollTo) {
      scrollToSection(location.state.scrollTo);
    }
  }, [location]);

  const invokeScrollToSection = (sectionId) => {
    closeMobileNav();
    scrollToSection(sectionId);
  };

  return (
    <div>
      {/* Helmet section */}
      <Helmet>
        <title> Płyty PIR Nowy Sącz Nal-Panel - dystrybutor Małopolska</title>
        <meta name="description" content="Płyty PIR w Nowym Sączu – idealna izolacja termiczna do budynków. Skorzystaj z oferty Na-Panel, by poprawić efektywność cieplną swojego obiektu"/>
      </Helmet>
      {/*End of Helmet Section*/}
      <header className={scrollY > 0 ? "scroll" : ""}>
        <div className="header-content">
          <div className="logo">
            <a href="#">
              <img src="/nal_panel.jpg" alt="Logo" />
            </a>
          </div>
          <div
            className={`mobile-nav-icon ${isMobileNavOpen ? "open" : ""}`}
            onClick={toggleMobileNav}
          >
            <div className="bar"></div>
            <div className="bar"></div>
            <div className="bar"></div>
          </div>
          <nav className="desktop-view">
            <ul>
              <li>
                <a onClick={() => scrollToSection("about")}>O nas</a>
              </li>
              <li>
                <Link to="/plyty-pir">Płyty PIR</Link>
              </li>
              <li>
                <Link to="/plyty-warstwowe">Płyty warstwowe</Link>
              </li>
              <li>
                <Link to="/kontakt">Kontakt</Link>
              </li>
            </ul>
          </nav>
        </div>
        <div className={`mobile-view ${isMobileNavOpen ? "open" : ""}`}>
          <ul>
            <li>
              <a onClick={() => invokeScrollToSection("about")}>O nas</a>
            </li>
            <li>
              <Link to="/plyty-pir" onClick={closeMobileNav}>
                Płyty PIR
              </Link>
            </li>
            <li>
              <Link to="/plyty-warstwowe" onClick={closeMobileNav}>
                Płyty Warstwowe
              </Link>
            </li>
            <li>
              <Link to="/kontakt" onClick={closeMobileNav}>
                Kontakt
              </Link>
            </li>
          </ul>
        </div>
      </header>
      <main>
        <section id="about">
          <div class="about-content">
            <div id="text">
              <h1 id="welcome-text">
              Płyty PIR - Najlepsza Izolacja Termiczna
              <br />
              Nowy Sącz
              </h1>
              <p>
              Zapewniamy najwyższej jakości płyty PIR oraz płyty warstwowe do izolacji termicznej. Skontaktuj się z nami, aby uzyskać darmową wycenę i doradztwo techniczne.
              </p>
            </div>
            <div id="banner">
              <img src="nal_panel.jpg" alt="logo"></img>
            </div>
          </div>
          <div class="about-content">
          <div class="about-padding">
          <h2>Dlaczego warto wybrać płyty PIR?</h2>
          <p>Płyty PIR (poliizocyjanurat) to jeden z najbardziej efektywnych materiałów izolacyjnych dostępnych na rynku. Charakteryzują się niskim współczynnikiem przewodzenia ciepła, co czyni je idealnym wyborem do izolacji domów, magazynów oraz innych budynków.</p>
          <ul>
            <li>Najwyższa izolacyjność termiczna</li>
            <li>Odporność na wilgoć i ogień</li>
            <li>Lekkość i łatwość montażu</li>
            <li>Długa żywotność</li>
            </ul>
            </div>
            </div>
            <div class="about-content">
              <div class="about-padding">
              <h2>Oferta Nal-Panel: polski dystrybutor płyt warstwowych i płyt PIR</h2>
              <p> W naszej ofercie znajdziesz różnorodne płyty PIR o różnych grubościach i wymiarach, idealne do zastosowania w różnych typach budynków. 
                Działamy na terenie Nowego Sącza i całego województwa małopolskiego.</p>
            <div id ="buttons-plyty">
            <Link to="/plyty-pir">
            <button class="p-button">
            Płyty PIR
            </button>
            </Link>
            <Link to="/plyty-warstwowe">
            <button class="p-button">
              Płyty warstwowe
            </button>
            </Link>
            </div>
            </div>
            </div>
            <div class="about-content">
              <div class="about-padding">
              <Opinie/>
              </div>
            </div>
            <div class="about-content">
              <div class="about-padding">
              <h2>FAQ - Płyty PIR</h2>
              <FAQ/>
                </div>
            </div>
        </section>
        <iframe id="home-map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3496.268417299164!2d20.624184000000003!3d49.62895760000001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x473dfb532f1747d5%3A0xc0aa005e4f85b943!2sNal-Panel%20P%C5%82yty%20warstwowe%20i%20izolacyjne%2C%20p%C5%82yty%20PIR!5e1!3m2!1spl!2spl!4v1726664628518!5m2!1spl!2spl" width="100%" height="350" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>

      </main>
      <footer id="footer">
        <div className="footer-content">
          <div className="logo">
            <img src="/nal_panel_removedbg.png" alt="Footer Logo" />
          </div>
          <p>
            &copy; {new Date().getFullYear()} Nal-Panel. Wszelkie prawa
            zastrzeżone.
          </p>
        </div>
      </footer>
      <ScrollToTop/>
    </div>
  );
};

export default Home;
